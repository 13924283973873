:root {
  --color: #111;
  --paper: #C0C0C0;
  --paper-dark: #8C8C8C;
  --highlight-light: #fff;
  --highlight-dark: #DFDFDF;
  --shadow-light: #808080;
  --shadow-dark: #000;
  --primary: #000080;
  --secondary: #1084D0;
}
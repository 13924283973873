@import "variables";

html {
  background-image: url("../images/clouds.png");
  background-size: cover;
  color: var(--color);
}

.paper {
  background-color: var(--paper);
  color: var(--color);
  box-shadow: inset -1px -1px 0 var(--shadow-dark),
              inset 1px 1px 0 var(--highlight-light),
              inset -2px -2px 0 var(--shadow-light),
              inset 2px 2px 0 var(--highlight-dark);
}

.banner {
  padding: 20px;
}

.links {
  margin-top: 10px;

  .paper {
    padding: 10px 20px;
  }
}

hr {
  border-top: 1px solid var(--shadow-light);
  border-bottom: 1px solid var(--highlight-dark);
}

h1, h2 {
  margin: 0;
}

marquee {
  color: red;
  font-size: 2rem;
  margin-bottom: 8px;
}

.shadow {
  --shadow: rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 1px var(--shadow), 2px 2px 1px var(--shadow), 3px 3px 1px var(--shadow), 4px 4px 1px var(--shadow);
}

.rainbow {
  background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
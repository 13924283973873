:root {
  --color: #111;
  --paper: silver;
  --paper-dark: #8c8c8c;
  --highlight-light: #fff;
  --highlight-dark: #dfdfdf;
  --shadow-light: gray;
  --shadow-dark: #000;
  --primary: navy;
  --secondary: #1084d0;
}

html {
  color: var(--color);
  background-image: url("clouds.1b8e61b3.png");
  background-size: cover;
}

.paper {
  background-color: var(--paper);
  color: var(--color);
  box-shadow: inset -1px -1px 0 var(--shadow-dark), inset 1px 1px 0 var(--highlight-light), inset -2px -2px 0 var(--shadow-light), inset 2px 2px 0 var(--highlight-dark);
}

.banner {
  padding: 20px;
}

.links {
  margin-top: 10px;
}

.links .paper {
  padding: 10px 20px;
}

hr {
  border-top: 1px solid var(--shadow-light);
  border-bottom: 1px solid var(--highlight-dark);
}

h1, h2 {
  margin: 0;
}

marquee {
  color: red;
  margin-bottom: 8px;
  font-size: 2rem;
}

.shadow {
  --shadow: #0003;
  text-shadow: 1px 1px 1px var(--shadow), 2px 2px 1px var(--shadow), 3px 3px 1px var(--shadow), 4px 4px 1px var(--shadow);
}

.rainbow {
  background-image: linear-gradient(to left, violet, indigo, #00f, green, #ff0, orange, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*# sourceMappingURL=index.38bbfd0b.css.map */
